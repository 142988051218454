.services { 
    width: 100%;
    padding: 76px 20px 40px;
    @media(max-width: $md2) {
        padding-top: 150px;
    }
	.services__container { 
        .services__flex {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 130px;
            margin-bottom: 80px;
            &:last-child {margin-bottom: 0;}
            @media(max-width: $md1) {
                gap: 40px;
            }

            @media(max-width: $md2) {
                flex-direction: column;
            }
            .services__left { 
                width: 100%;
                max-width: 732px;
                @media(max-width: $md1) {
                    max-width: 500px;
                }
                @media(max-width: $md3) {
                    max-width: unset;
                }
                .services__map { 
                    width: 100%;
                    padding: 86px 78px 62px;
                    border: 2px solid var(--fe-6839, #998861);
                    background: #F5F6F3;
                    margin-bottom: 30px;
                    @media(max-width: $md1) {
                        padding: 40px 20px ;
                    }
                    span { 
                        color: #222220;
                        font-family: Archivo;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 24px */
                        letter-spacing: 2.8px;
                        display: block;
                        margin-bottom: 32px;
                        position: relative;
                        padding-left: 86px;
                        @media(max-width: $md1) {
                            font-size: 16px;
                            margin-bottom: 30px;
                        }
                        &::before,&::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: block;
                            height: 4px;
                        }
                        &::before {
                            background: #998861;
                            width: 38px;
                        }
                        &::after {
                            background: #222220;
                            width: 20px;
                            left: 38px;
                        }
                        @media(max-width: $md2) {
                            font-size: 16px;
                            padding-left: 40px;
                            &::before {
                                width: 20px;
                            }
                            &::after {
                                width: 10px;
                                left: 20px;
                            }
                        }
                    }
    
                    h2 { 
                        color: var(--18304-bi, #222220);
                        font-family: Archivo;
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%; 
                        margin-bottom: 32px;
                        @media(max-width: $md1) {
                            font-size: 32px;
                        }
                    }
    
                    p { 
                        color: var(--66727-e, #66727E);
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 200%;
                        margin-bottom: 50px;
                    }
    
                    img { 
                        width: 100%;
                    }
                }

                button {
                    width: 100%;
                    max-width: 276px;
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    background: var(--fe-6839, #998861);
                    color: var(--White, #FFF);
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            .services__right {
                @media(max-width: $md2) {
                    width: 100%;
                    max-width: unset;
                }
            }
            .services__grid { 
                width: 100%;
                max-width: 600px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 78px 100px;
                margin-top: 40px;
                @media(max-width: $md1) {
                    grid-gap: 40px 20px;
                }
                @media(max-width: $md2) {
                    max-width: unset;
                }
                .services__item { 
                    width: 100%;
                    span { 
                        color: #222220;
                        font-family: Archivo;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 24px */
                        letter-spacing: 2.8px;
                        display: block;
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 86px;
                        @media(max-width: $md1) {
                            font-size: 16px;
                        }
                        &::before,&::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: block;
                            height: 4px;
                        }
                        &::before {
                            background: #998861;
                            width: 38px;
                        }
                        &::after {
                            background: #222220;
                            width: 20px;
                            left: 38px;
                        }
                        @media(max-width: $md2) {
                            font-size: 16px;
                            padding-left: 40px;
                            &::before {
                                width: 20px;
                            }
                            &::after {
                                width: 10px;
                                left: 20px;
                            }
                        }
                    }
    
                    h4 { 
                        color: var(--18304-bi, #222220);
                        font-family: Archivo;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 120%; 
                        margin-bottom: 10px;
                        max-width: 254px;
                        @media(max-width: $md1) {
                            font-size: 24px;
                        }
                    }
    
                    p { 
                        color: var(--66727-e, #66727E);
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        max-width: 254px;
                        line-height: 200%; 
                    }
                }
            }
        }

        .services__products {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 50px;
        }
	}
}