.banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    img {
        width: 100%;
        height: 792px;
        object-fit: cover;
        @media(max-width: $md1) {
            height: 600px;
        }

        @media(max-width: $md2) {
            height: 400px;
        }

        @media(max-width: $md3) {
            height: 300px;
        }
    }
}