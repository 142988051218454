.navigation {
    width: 100%;
    padding: 0 20px 70px;
    @media(max-width: $md2) {
        display: none;
    }
    .navigation__container {
        max-width: 1300px;
        ul {
            width: 100%;

            li {
                a {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 40px 20px;
                    border-bottom: 1px solid;
                    color: var(--Accent-100, #212529);
                    font-family: Archivo;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &.active {
                        font-style: italic;
                        font-weight: 800;
                    }
                }
            }
        }
    }
}