.real-estate { 
    width: 100%;
    padding: 76px 20px 100px;
    position: relative;
    @media(max-width: $md2) {
        margin-top: 20px;
        padding-left: 120px;
        padding-right: 40px;
    }
    @media(max-width:$md3) {
        padding-left: 80px;
    }
    &::before,&::after {
        content: "";
        position: absolute;
        right: 13%;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 62%;
        background: #000;
        @media(max-width: $md2) {
            right: 14px;
            z-index: 2;
            max-width: 84%;
        }
    }
    &::after {
        right: 0;
        background: #DC9900;
        max-width: 13%;
        @media(max-width: $md2) {
            background: var(--fe-6839, #998861);
            height: 234px;
            z-index: 1;
        }
    }
	.real-estate__container { 
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 74px;
        position: relative;
        z-index: 2;
        @media(max-width: $md2) {
            justify-content: flex-end;
            &::before {
                content: "";
                width: 100%;
                max-width: 20%;
                height: 488px;
                top: 42px;
                left: -120px;
                background-image: url("../../../public/img/Frame 115 (1).png");
                background-size: cover;
                position: absolute;
                
            }
        }
		.real-estate__img { 
            width: 100%;
            margin-top: 80px;
            max-width: 842px;
            @media(max-width: $md2) {
                display: none;
            }
			img { 
                width: 100%;
                height: 648px;
                object-fit: cover;
                border: 2px solid #D9D9D9;
			}
		}

		.real-estate__text { 
            width: 100%;
            max-width: 576px;
			span { 
                color: var(--f-9-f-9-f-9, #F9F9F9);
                font-family: Archivo;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 24px */
                letter-spacing: 2.8px;
                display: block;
                margin-bottom: 32px;
                position: relative;
                padding-left: 86px;
                &::before,&::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    height: 4px;
                }
                &::before {
                    background: var(--fe-6839, #998861);
                    width: 38px;
                }
                &::after {
                    background: var(--f-9-f-9-f-9, #F9F9F9);
                    width: 20px;
                    left: 38px;
                }
                @media(max-width: $md2) {
                    font-size: 16px;
                    padding-left: 40px;
                    &::before {
                        width: 20px;
                    }
                    &::after {
                        width: 10px;
                        left: 20px;
                    }
                }
                
			}

			h2 { 
                color: var(--f-9-f-9-f-9, #F9F9F9);
                font-family: Archivo;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 32px;
                @media(max-width: $md2) {
                    font-size: 24px;
                    margin-bottom: 24px;
                }
                @media(max-width:$md3) {
                    font-size: 20px;
                }
			}

			p { 
                color: var(--f-9-f-9-f-9, #F9F9F9);
                font-family: Archivo;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 200%; /* 32px */
                margin-bottom: 32px;
                @media(max-width: $md2) {
                    margin-bottom: 10px;
                }
                @media(max-width:$md3) {
                    font-size: 14px;
                }
			}
		}
	}
}