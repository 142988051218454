$fontFamily: "sans-serif";
$fontSize: 16;
//</Шрифт по умолчанию>==========================================================================================

//</ШРИФТЫ>=======================================================================================================

//<ПЕРЕМЕННЫЕ>==========================================================================================================
$minWidth: 320px;
$maxWidth: 1920;
$maxWidthContainer: 1170;
$md1: 1684px;
$md2: 1024px;
$md3: 768px;
$md4: 480px;

$mainColor: #000;
$mainBackground: #fff;

.page {
	flex: 1 1 auto;
}

.container {
	max-width: 1684px;
	width: 100%;
	margin: 0 auto;

	@media(max-width: $md1) {
		max-width: 1240px;
	}
}

//media screen
// @media(max-width: $md1)
// @media(max-width: $md2)
// @media(max-width: $md3)
// @media(max-width: $md4)

//uikit elements

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 16px 26px;
	background: var(--fe-6839, #998861);
	color: var(--White, #FFF);
	font-family: Archivo;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	max-width: max-content
}

.error {
	width: 100%;
	padding: 60px 20px;
	min-height: calc(100vh - 694px);
	margin-top: 110px;
	.error__container {
		max-width: 600px;
		h1 {
			color: var(--18304-bi, #222220);
			text-align: center;
			font-family: Archivo;
			font-size: 100px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 60px;
		}

		a {
			margin: 0 auto;
			max-width: 200px;
		}
	}
}

@import "./components/Footer/footer.scss";
@import "./components/Header/header.scss";
@import "./components/Main/style.scss";
@import "./components/Navigation/style.scss";
@import "./components/RealEstate/style.scss";
@import "./components/Equipment/style.scss";
@import "./components/Strategies/style.scss";
@import "./components/Contacts/style.scss";
@import "./components/ProductItem/style.scss";
@import "./components/Tarifs/style.scss";
@import "./components/DiscoverMain/style.scss";
@import "./components/Services/style.scss";
@import "./components/TaxAdvice/style.scss";
@import "./components/Languages/style.scss";
@import "./components/Banner/style.scss";
@import "./components/Cases/style.scss";
@import "./components/Registration/style.scss";
@import "./components/Loader/style.scss";
@import "./components/Confirmation/style.scss";
@import "./components/Login/style.scss";
@import "./components/InfoBlock/style.scss";