.strategies { 
    width: 100%;
    position: relative;
	.strategies__container { 
        width: 100%;
        max-width: unset;
		.strategies__flex { 
            max-width: 1920px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            @media(max-width: $md2) {
                flex-direction: column;
            }
			.strategies__img { 
                width: 100%;
                max-width: 50%;
                position: relative;
                @media(max-width: $md2) {
                    max-width: unset;
                }
                h2 {
                    color: var(--18304-bi, #222220);
                    font-family: Archivo;
                    font-size: 70px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; 
                    padding: 0px 50px;
                    position: absolute;
                    left: 0;
                    bottom: 234px;
                    @media(max-width: $md1) {
                        font-size: 46px;
                        padding: 0 20px;
                        bottom: 40px;
                    }

                    @media(max-width: $md3) {
                        font-size: 32px;
                    }
                }
				img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @media(max-width: $md1) {
                        height: 600px;
                    }
                    @media(max-width: $md2) {
                        height: 400px;
                    }
                    @media(max-width: $md3) {
                        height: 300px;
                    }
				}
			}

			.strategies__right { 
                width: 100%;
                max-width: 50%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @media(max-width: $md2) {
                    max-width: unset;
                }
				.strategies__img { 
                    max-width: unset;
                    width: 100%;
					img { 
                        width: 100%;
                        height: 500px;
                        object-fit: cover;
                        @media(max-width: $md1) {
                            height: 300px;
                        }
                        @media(max-width: $md3) {
                            height: 220px;
                        }
					}
				}

				.strategies__item { 
                    width: 100%;
                    padding: 50px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    flex-direction: column;
                    border: 2px solid var(--fe-6839, #998861);
                    background: #F5F6F3;
                    @media(max-width: $md1) {
                        padding: 20px;
                    }
					.icon { 
                        margin-bottom: 20px;
                        @media(max-width: $md3) {
                            svg {
                                width: 70px;
                                height: auto;
                            }
                        }
					}

					p { 
                        color: var(--18304-bi, #222220);
                        font-family: Archivo;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        @media(max-width: $md1) {
                            font-size: 20px;
                        }

                        @media(max-width: $md3) {
                            font-size: 16px;
                        }
					}
				}
			}
		}
	}

	
}

.strategies__line { 
    width: 100%;
    padding: 58px 0;
    background: var(--fe-6839, #998861);
    overflow: hidden;
    white-space: nowrap;
    @media(max-width: $md2) {
        padding: 20px 0;
    }
    p { 
        color: var(--18304-bi, #222220);
        font-family: Archivo;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 84px */
        display: inline-block;
        padding-left: 100%; /* Запускаем анимацию с правой стороны */
        animation: marquee 10s linear infinite;
        @media(max-width: $md2) {
            font-size: 32px;
        }
        span { 
            color: #FFF;
        }
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}