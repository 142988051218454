.confirmation {
    width: 100%;
    height: calc(100vh - 110px);
    padding: 140px 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: $md2) {
        height: calc(100vh - 82px);
        padding: 60px 20px;
    }
    .confirmation__container {
        max-width: 600px;
        h1 {
            color: var(--18304-bi, #222220);
            text-align: center;
            font-family: Archivo;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
            @media(max-width: $md2) {
                font-size: 30px;
            }
        }

        p {
            color: #252430;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; 
            text-align: center;
            margin-bottom: 30px;
        }

        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 260px;
            height: 52px;
            border-radius: 4px;
            background: #998861;
            color: #E6E9F5;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin: 0 auto;
        }
    }
}