.contacts { 
    width: 100%;
    padding: 134px 20px;
	.contacts__container { 
        max-width: 1200px;
		h2 { 
            color: var(--18304-bi, #222220);
            text-align: center;
            font-family: Archivo;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 60px */
            margin-bottom: 136px;
            @media(max-width: $md2) {
                font-size: 28px;
            }
		}

		form { 
            width: 100%;
			.flex { 
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 50px;
                gap: 50px;
                @media(max-width: $md2) {
                    flex-direction: column;
                }
				.input-text { 
                    margin-bottom: 0;
				}
			}

			.input-text { 
                display: block;
                width: 100%;
				input { 
                    width: 100%;
                    padding-bottom: 10px;
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 200%; 
                    border-bottom: 1px solid #66727E;
                    &::placeholder {
                        color: var(--66727-e, #66727E);
                    }
				}
			}

            .contacts__submit {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 50px;
                .button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 200px;
                    height: 56px;
                    background: var(--fe-6839, #998861);
                    color: var(--White, #FFF);
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
		}
	}
}