.tax-advice {
    width: 100%;
    padding: 0 20px 70px;
    .tax-advice__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media(max-width: $md2) {
            flex-direction: column;
        }
        .tax-advice__left {
            width: 100%;
            max-width: 846px;
            @media(max-width: $md2) {
                max-width: unset;
            }
            .img {
                width: 100%;
                img {
                    width: 100%;
                    height: 516px;
                    object-fit: cover;
                    border: 2px solid #D9D9D9;
                    @media(max-width: $md2) {
                        height: 330px;
                    }
                }
            }
            .text {
                width: 100%;
                padding: 50px 20px;
                background: var(--18304-bi, #222220);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 50px;
                .tax-advice__left-item {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 712px;
                    width: 100%;
                    @media(max-width: $md4) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                    }
                    .info {
                        width: 100%;
                        position: relative;
                        max-width: 194px;
                        padding-right: 20px;
                        margin-right: 20px;
                        border-right: 2px solid #998861;
                        @media(max-width: $md3) {
                            max-width: 140px;
                            padding-right: 10px;
                            margin-right: 10px;
                        }
                        @media(max-width: $md4) {
                            border-right: unset;
                            margin-right: 0;
                            padding-right: 0;
                            padding-bottom: 10px;
                            border-bottom: 2px solid #998861;
                            max-width: unset;
                        }
                        span {
                            display: block;
                            margin-bottom: 20px;
                            color: var(--fe-6839, #998861);
                            font-family: Archivo;
                            font-size: 50px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 120%;
                            @media(max-width: $md3) {
                                font-size: 26px;
                            }
                        }
                        p {
                            color: var(--f-9-f-9-f-9, #F9F9F9);
                            font-family: Archivo;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                            letter-spacing: 1.28px;
                        }
                    }
                    p {
                        max-width: 498px;
                        color: var(--f-9-f-9-f-9, #F9F9F9);
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 200%;
                        @media(max-width: $md3) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .tax-advice__right {
            width: 100%;
            max-width: 846px;
            margin-top: 80px;
            @media(max-width: $md2) {
                max-width: unset;
                margin-top: 0;
            }
            .text {
                width: 100%;
                padding: 70px 20px 100px;
                background: var(--fe-6839, #998861);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @media(max-width: $md2) {
                    padding: 50px 20px;
                }
                h2 {
                    max-width: 712px;
                    color: var(--f-9-f-9-f-9, #F9F9F9);
                    font-family: Archivo;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 60px */
                    margin-bottom: 32px;
                    @media(max-width: $md2) {
                        font-size: 32px;
                    }
                }

                p {
                    max-width: 712px;
                    color: var(--f-9-f-9-f-9, #F9F9F9);
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 200%; /* 32px */
                }
            }

            .img {
                width: 100%;
                img {
                    width: 100%;
                    height: 516px;
                    object-fit: cover;
                    border: 2px solid #D9D9D9;
                    @media(max-width: $md2) {
                        height: 330px;
                    }
                }
            }

        }
    }
}