.registration {
    width: 100%;
    padding: 60px 20px 80px;
    margin-top: 112px;
    position: relative;

    @media(max-width: $md3) {
        margin-top: 80px;
    }

    .registration__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .registration__form {
            width: 100%;
            max-width: 69%;
            padding: 42px 38px 66px;
            padding-right: 30%;
            background: var(--18304-bi, #222220);
            position: relative;

            @media(max-width: $md1) {
                padding-right: 12%;
            }

            @media(max-width: $md2) {
                padding: 40px 20px;
                max-width: 80%;
            }

            &::before {
                content: "";
                width: 100%;
                max-width: 20%;
                height: 100%;
                position: absolute;
                left: 100%;
                top: 0;
                background: var(--fe-6839, #998861);
            }

            .registration__text {
                width: 100%;
                max-width: 652px;
                margin-bottom: 28px;

                .subtitle {
                    color: var(--f-9-f-9-f-9, #F9F9F9);
                    font-family: Archivo;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: 2.8px;
                    display: block;
                    margin-bottom: 32px;
                    position: relative;
                    padding-left: 86px;

                    span {
                        color: #FFA800;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        height: 4px;
                    }

                    &::before {
                        background: var(--fe-6839, #998861);
                        width: 38px;
                    }

                    &::after {
                        background: var(--f-9-f-9-f-9, #F9F9F9);
                        width: 20px;
                        left: 38px;
                    }

                    @media(max-width: $md2) {
                        font-size: 16px;
                        padding-left: 40px;

                        &::before {
                            width: 20px;
                        }

                        &::after {
                            width: 10px;
                            left: 20px;
                        }
                    }
                }

                h2 {
                    color: var(--f-9-f-9-f-9, #F9F9F9);
                    font-family: Archivo;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: 28px;
                    @media(max-width: $md2) {
                        font-size: 24px;
                        margin-bottom: 24px;
                    }
                }

                p {
                    color: #7F7F7F;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @media(max-width: $md2) {
                        font-size: 16px;
                    }
                }
            }

            .input-file {
                width: 100%;
                margin-bottom: 72px;
                max-width: 674px;
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                position: relative;
                padding-bottom: 40px;
                border-bottom: 2px solid #3D3D3D;
                @media(max-width: $md2) {
                    padding-bottom: 20px;
                    margin-bottom: 40px;
                }
                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    appearance: none;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    cursor: pointer;
                    opacity: 0;
                }

                .files {
                    width: 100%;
                    height: 48px;
                    padding: 0 14px;
                    border-radius: 4px;
                    border: 1px solid #3D3D3D;
                    border-right: unset;
                    background: #242424;

                    p {
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                }

                .btn {
                    max-width: 208px;
                    width: 100%;
                    height: 48px;
                    border-radius: 4px;
                    background: #CF8800;
                    border: unset;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .input-text {
                display: block;
                width: 100%;
                margin-bottom: 38px;
                max-width: 674px;
                @media(max-width: $md2) {
                    margin-bottom: 16px;
                }
                input,
                textarea {
                    width: 100%;
                    height: 48px;
                    padding: 0 14px;
                    border-radius: 4px;
                    border: 1px solid #3D3D3D;
                    background: #242424;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .error-message {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 6px 4px;
                    display: block;
                }

                textarea {
                    height: 144px;
                    margin-bottom: 30px;
                    padding: 12px 14px;
                    resize: none;
                    @media(max-width: $md2) {
                        margin-bottom: 16px;
                    }
                }
            }

            .input-checkbox {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;
                max-width: 720px;
                margin-bottom: 30px;

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    appearance: none;
                    cursor: pointer;
                    z-index: 2;
                    opacity: 0;
                    &:checked ~ span {
                        svg {display: block;}
                    }
                }

                span {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    border: 1px solid #3D3D3D;
                    background: #242424;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        display: none;
                    }
                }

                p {
                    color: #7F7F7F;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .registration__buttons {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 674px;

                label {
                    width: 100%;
                    max-width: 208px;
                    display: block;
                }

                button,
                input {
                    max-width: 208px;
                    width: 100%;
                    height: 48px;
                    border-radius: 4px;
                    background: #CF8800;
                    border: unset;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .registration__bottom {
                width: 100%;
                max-width: 674px;
                margin-top: 56px;
                padding-top: 40px;
                border-top: 2px solid #3D3D3D;

                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    color: #7F7F7F;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    span {
                        color: #C99E06;
                    }
                }
            }
        }

        .registration__img {
            position: absolute;
            width: 100%;
            max-width: 50%;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            @media(max-width: $md1) {
                max-width: 40%;
            }

            @media(max-width: $md2) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }
}