@keyframes loadAnim {
    0% {
        height: 50px;
    }

    50% {
        height: 10px;
    }

    100% {
        height: 50px;
    }
}

.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #998861;
    z-index: 999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    span {
        width: 10px;
        height: 20px;
        background-color: #fff;
        display: block;
        border-radius: 4px;
        transition: all 0.5s;
        animation: loadAnim 0.5s infinite;

        &:nth-child(2) {
            animation: loadAnim 1s infinite;
        }

        &:nth-child(3) {
            animation: loadAnim 1.5s infinite;
        }
    }
}
