.header { 
	width: 100%;
	padding: 0 20px;
	border-bottom: 1px solid #8A8A8A;
	position: fixed;
	background-color: #fff;
	z-index: 20;
	top: 0;
	left: 0;
	min-height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media(max-width: $md2) {
		min-height: 80px;
	}
	@media(max-width: $md3) {
		padding: 16px 20px;
	}
	.header__container { 
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		max-width: 1760px;
		.logo { 
			width: 100%;
			max-width: 202px;
			display: block;
			img { 
				width: 100%;
				max-width: 80px;
				@media(max-width: $md3) {
					max-width: 60px;
				}
			}
		}

		ul { 
			display: flex;
			justify-content: center;
			align-items: center;
			@media(max-width: $md3) {
				display: none;
			}
			li { 
				a { 
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 40px 14px;
					color: var(--18304-bi, #222220);
					text-align: center;
					font-family: Archivo;
					font-size: 20px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;
					&:hover {
						border-color: #222220;
						color: #998861;
					}
				}
			}
		}

		.header__right { 
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 300px;
			gap: 10px;
			@media(max-width: $md3) {
				justify-content: flex-end;
			}

			.search-btn { 
				color: var(--18304-bi, #222220);
				text-align: center;
				font-family: Archivo;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				
			}

			.langs { 
				position: absolute;
				right: -8px;
				top: 90px;
				width: 40px;
				@media(max-width: $md1) {
					right: -12px;
				}
				button { 
					margin-bottom: 18px;
					border: 4px solid transparent;
					border-radius: 4px;
					overflow: hidden;
					transition: all 0.5s;
					height: 38px;
					&.active {
						border-color: #000;
					}
					@media(max-width: $md1) {
						margin-bottom: 6px;
						border-width: 1px;
						height: 25px;
						svg {
							width: 30px;
							height: auto;
						}
					}
				}

				span { 
					color: var(--66727-e, #66727E);
					font-family: Archivo;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 200%; 
					transform: rotate(-90deg);
					display: block;
					margin-top: 56px;
					@media(max-width: $md1) {
						display: none;
					}
				}
			}

		}
	}

	.header__menu {
		max-width: 80vw;
		position: absolute;
		top: 110px;
		right: 0;
		overflow: hidden;
		padding: 50px 0px;
		height: calc(100vh - 110px);
		background: #FFF;
		box-shadow: 0px 4px 18.2px 0px rgba(0, 0, 0, 0.63);
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		transition: all 0.5s;
		@media(max-width: $md2) {
			height: calc(100vh - 80px);
			top: 80px;
		}
		.header__menu-box {
			min-width: max-content;
			padding: 0 20px;
			max-width: 80%;
			width: 100%;
			@media(max-width: $md3) {
				max-width: 100%;
			}
			p {
				color: var(--18304-bi, #222220);
				font-family: Archivo;
				font-size: 40px;
				margin-bottom: 40px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
			}

			.navigation {
				max-width: unset;
				padding: 0;
				display: block;
				.navigation__container ul li a {
					padding: 16px 0;
					font-size: 20px;
					svg {
						width: 10px;
						height: auto;
					}
				}
			}
		}
	}
}