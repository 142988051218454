.tarifs {
    width: 100%;
    padding: 110px 20px 160px;
    margin-top: 112px;
    position: relative;

    @media(max-width: $md1) {
        padding: 100px 20px 100px;
    }

    @media(max-width: $md2) {
        padding: 80px 20px;
    }

    @media(max-width: $md3) {
        margin-top: 80px;
        padding: 40px 20px;
    }

    .tarifs__container {
        h1 {
            color: var(--18304-bi, #222220);
            text-align: center;
            font-family: Archivo;
            font-size: 100px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 60px;

            @media(max-width: $md1) {
                margin-bottom: 40px;
                font-size: 60px;
            }

            @media(max-width: $md2) {
                font-size: 40px;
            }

            @media(max-width: $md3) {
                font-size: 28px;
            }

            span {
                font-style: italic;
                font-weight: 900;
                display: block;
            }
        }

        .tarifs__grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            border-radius: 4px;
            border: 1px solid #E6E9F5;
            background: #FFF;

            @media(max-width: $md2) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media(max-width: $md3) {
                grid-template-columns: repeat(1, 1fr);
            }

            .tarifs__left {
                width: 100%;
                border-right: 1px solid #E6E9F5;

                @media(max-width: $md2) {
                    display: none;
                }

                .tarifs__left-title {
                    width: 100%;
                    border-bottom: 1px solid #E6E9F5;
                    padding: 20px 32px;
                    min-height: 184px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 12px;

                    h4 {
                        color: #252430;
                        font-family: Roboto;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 12px;

                        @media(max-width: $md1) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        span {
                            display: block;
                            border-radius: 20px;
                            border: 0.5px solid #858BA0;
                            padding: 10px 20px;
                            color: #252430;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }

                    p {
                        color: #858BA0;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }

                .tarifs__left-item {
                    width: 100%;
                    padding: 20px 32px;
                    border-bottom: 1px solid #E6E9F5;
                    min-height: 80px;
                    @media(max-width: $md3) {
                        min-height: unset;
                    }
                    p {
                        color: #252430;
                        font-family: Inter;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }

            .tarifs__item {
                width: 100%;
                border-right: 1px solid #E6E9F5;

                .tarifs__item-title {
                    width: 100%;
                    border-bottom: 1px solid #E6E9F5;
                    padding: 20px 32px;
                    min-height: 184px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 28px;

                    @media(max-width: $md2) {
                        padding: 16px;
                        gap: 16px;
                    }

                    h4 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        color: #252430;
                        font-family: Roboto;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;

                        @media(max-width: $md2) {
                            font-size: 32px;
                        }

                        span {
                            color: #858BA0;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }

                    button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 52px;
                        border-radius: 4px;
                        background: #998861;
                        color: #E6E9F5;
                        text-align: center;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px;
                    }
                }

                .tarifs__item-info {
                    width: 100%;
                    padding: 20px 32px;
                    min-height: 80px;
                    border-bottom: 1px solid #E6E9F5;

                    @media(max-width: $md2) {
                        padding: 16px;
                        min-height: 60px;
                    }

                    p {
                        color: #252430;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: center;

                        @media(max-width: $md2) {
                            font-size: 12px;
                        }

                        @media(max-width: $md3) {
                            font-size: 14px;
                        }

                        span {
                            color: #858BA0;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            display: block;
                        }

                        .bold {
                            display: inline;
                            color: #252430;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 900;
                            line-height: 20px;
                        }
                    }

                    .flex {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 4px;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }
    }

    &.singl-tarifs {
        padding: 0 20px 60px;
        .tarifs__container {
            max-width: 600px;
            .tarifs__grid {
                grid-template-columns: 1fr;
            }
        }
    }
}