.footer { 
    width: 100%;
    background: var(--18304-bi, #222220);
	.footer__top { 
        width: 100%;
        padding: 56px 20px;
		.footer__container { 
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;
            @media(max-width: $md2) {
                flex-direction: column;
            }
			.footer__left { 
                width: 100%;
                max-width: 712px;
				.logo { 
                    width: 100%;
                    max-width: 202px;
                    display: block;
                    margin-bottom: 20px;
                    @media(max-width: $md2) {
                        max-width: 170px;
                    }
					img { 
                        width: 100%;
					}
				}

				p { 
                    color: var(--f-9-f-9-f-9, #F9F9F9);
                    font-family: Archivo;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 170%; /* 47.6px */
                    margin-bottom: 40px;
                    @media(max-width: $md2) {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
				}

				.footer__subscribe { 
                    width: 100%;
					.input-text { 
                        color: var(--f-9-f-9-f-9, #F9F9F9);
                        font-family: Archivo;
                        font-size: 21px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 170%; /* 35.7px */
                        letter-spacing: 4.2px;
                        text-transform: uppercase;
                        width: 100%;
                        display: block;
                        position: relative;
						input { 
                            width: 100%;
                            margin-top: 8px;
                            color: #8E9DAD;
                            font-family: Archivo;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 200%; 
                            border-bottom: 1px solid #FFF;
                            padding-bottom: 10px;
                            background-color: unset;
						}

						svg { 
                            position: absolute;
                            right: 0;
                            bottom: 14px;
						}
					}
				}
			}

			.footer__rigth { 
                width: 100%;
                max-width: 662px;
				.footer__menu { 
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 16px;
                    margin-bottom: 126px;
                    @media(max-width: $md2) {
                        margin-bottom: 70px;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        gap: 20px;
                    }
					.footer__menu-item { 
                        width: 100%;
                        max-width: 226px;
						h4 { 
                            color: #FFF;
                            font-family: Archivo;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%; /* 28.8px */
                            margin-bottom: 32px;
                            @media(max-width: $md2) {
                                font-size: 20px;
                            }
						}

						ul { 
							li { 
                                margin-bottom: 16px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
								a {     
                                    color: #FFF;
                                    font-family: Archivo;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 120%;
                                    @media(max-width: $md2) {
                                        font-size: 16px;
                                    }
								}
							}
						}
					}
				}

				.footer__soc { 
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 32px;
					p { 
                        color: #979797;
                        font-family: Archivo;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 200%;
					}
				}
			}
		}
	}

	.footer__bottom { 
        width: 100%;
        padding: 26px 20px ;
        border-top: 2px solid #998861;
		.footer__container {    
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;
			p { 
                color: #FFF;
                font-family: Archivo;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                text-align: right;
                max-width: 600px;
                &:first-child {
                    text-align: left;
                }
			}
		}
	}
}