.product-item {
    width: 100%;
    padding: 20px 20px 50px;
    border: 1px solid #DEDCDA;
    background: #998861;
    @media(max-width: $md2) {
        padding: 20px;
    }
    .img {
        width: 100%;
        display: block;
        margin-bottom: 26px;

        img {
            width: 100%;
            height: 308px;
            object-fit: cover;
        }
    }

    .product-item__text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        .bottom {
            width: 100%;
        }
        h4 {
            margin-bottom: 10px;

            a {
                color: var(--Tittle, #1F2744);
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0.06px;
                @media(max-width: $md2) {
                    font-size: 20px;
                }
            }
        }

        p {
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; 
            letter-spacing: 0.04px;
            margin-bottom: 24px;
            @media(max-width: $md2) {
                font-size: 14px;
            }
        }

        span {
            color: #000;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.1px;
            display: block;
            text-align: center;
            margin-bottom: 16px;
        }

        .buy-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            border: 1px solid #000;
            color: #000;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; 
            letter-spacing: 0.04px;
        }
    }
}