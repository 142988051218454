.discover-main { 
    width: 100%;
    padding: 278px 20px 320px;
    margin-top: 112px;
    position: relative;
    @media(max-width: $md1) {
        padding: 200px 20px 260px;
    }
    @media(max-width: $md2) {
        padding: 150px 20px;
    }

    @media(max-width: $md3) {
        margin-top: 80px;
        padding: 100px 20px;
    }
	.discover-main__container { 
		.discover-main__bg { 
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: absolute;
            left: 50%;
            top: 26px;
            max-width: 1684px;
            transform: translateX(-50%);
            gap: 16px;
            @media(max-width: $md1) {
                max-width: 1240px;
            }
            @media(max-width: $md2) {
                height: 100%;
                top: 0;
            }
			.discover-main__bg-item { 
                width: 100%;
                max-width: 420px;
                height: 390px;
                position: relative;

                &:nth-child(1),&:nth-child(4) {
                    top: 390px;
                }

                &:nth-child(2) {
                    left: -0px;
                }
                
                &:nth-child(3) {
                    top: 0px;
                    left: 0px;
                    img {
                        height: 780px;
                        @media(max-width: $md1) {
                            height: 580px;
                        }

                        @media(max-width: $md3) {
                            height: 400px;
                        }
                    }
                }

                @media(max-width: $md1) {
                    max-width: 320px;
                    height: 290px;
                    &:nth-child(1),&:nth-child(4) {
                        top: 290px;
                    }
    
                    &:nth-child(2) {
                        left: -20px;
                    }
                    
                    &:nth-child(3) {
                        top: 0;
                    }
                }

                @media(max-width: $md2) {
                    &:nth-child(1),&:nth-child(2) {
                        display: none;
                        top: 0;
                        left: 0;
                    }
                    
                    &:nth-child(3) {
                        top: 0;
                        left: 0;
                    }

                    &:nth-child(4) {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: unset;
                        top: unset;
                    }
                }

                @media(max-width: $md3) {
                    max-width: 200px;
                    height: 200px;
                }

				img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}

		.discover-main__text { 
            width: 100%;
            position: relative;
            z-index: 2;
			h1 { 
                color: var(--18304-bi, #222220);
                text-align: center;
                font-family: Archivo;
                font-size: 100px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 60px;
                @media(max-width: $md1) {
                    margin-bottom: 40px;
                    font-size: 60px;
                }
                @media(max-width: $md2) {
                    font-size: 40px;
                }

                @media(max-width: $md3) {
                    font-size: 28px;
                }
                span {
                    font-style: italic;
                    font-weight: 900;
                    display: block;
                }
			}

			a { 
                margin: 0 auto;
                @media(max-width: $md2) {
                    background-color: #C8820C;
                }
			}
		}
	}
}