.cases {
    width: 100%;
    padding: 56px 20px 30px;
    background: var(--18304-bi, #222220);
    .cases__container {
        .cases__title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 20px;
            margin-bottom: 64px;
            @media(max-width: $md1) {
                margin-bottom: 30px;
            }
            @media(max-width: $md3) {
                flex-direction: column;
            }
            .text {
                width: 100%;
                max-width: 956px;
                h2 {
                    color: var(--e-9-f-5-fe, #E9F5FE);
                    font-family: Archivo;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: 20px;
                    @media(max-width: $md2) {
                        font-size: 26px;
                    }
                    span {
                        font-style: italic;
                        font-weight: 100;
                    }
                }

                p {
                    color: #FFF;
                    font-family: Archivo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 200%;
                }
            }

            .cases__buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 14px;
            }
        }
        .cases__swiper {
            width: 100%;
            .swiper-slide {
                max-width: 400px;
                @media(max-width: $md2) {
                    max-width: 260px;
                }
                .cases__item {
                    width: 100%;
                    .img {
                        width: 100%;
                        margin-bottom: 32px;
                        img {
                            width: 100%;
                            height: 400px;
                            object-fit: cover;
                            @media(max-width: $md2) {
                                height: 220px;
                            }
                        }
                    }

                    .text {
                        width: 100%;
                        span {
                            color: var(--fe-6839, #998861);
                            font-family: Archivo;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%;
                            letter-spacing: 2px;
                            display: block;
                            margin-bottom: 10px;
                            @media(max-width: $md2) {
                                font-size: 16px;
                            }
                        }

                        h4 {
                            color: var(--e-9-f-5-fe, #E9F5FE);
                            font-family: Archivo;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 120%; 
                            @media(max-width: $md2) {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}